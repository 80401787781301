import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// import { Icon,Message,Button,Form,FormItem } from "element-ui";
// Vue.prototype.$message = Message;
// Vue.use(Icon);
// Vue.use(Button);
// Vue.use(Form);
// Vue.use(FormItem);
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
import {
  postHandler,
  getHandler
} from "./utils/request";
Vue.prototype.$postHandler = postHandler;
Vue.prototype.$getHandler = getHandler;
// var VConsole = require('./utils/vconsole.min.js');
// var vConsole = new VConsole();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
